import { MenuItemsType, DropdownMenuItemType, MoreIcon } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}
// 导航栏的配置数据
const config: (t: ContextApi['t'], isDark: boolean, languageCode?: string, chainId?: number) => ConfigMenuItemsType[] =
  (t, isDark, languageCode, chainId) =>
    [
      // {
      //   label: '理财板块',
      //   href: '/financial',
      //   // icon: 'firing',
      //   items: [
      //     {
      //       label: '创建理财',
      //       href: '/financial',
      //     },
      //     {
      //       label: '理财列表',
      //       href: '/financial/list',
      //     },
      //   ].map((item) => addMenuItemSupported(item, chainId)),
      // },
      {
        label: 'SOLANA',
        href: 'https://sol.gtokentool.com/',
        items: [],
      },
      {
        label: 'SUI',
        href: 'https://sui.gtokentool.com/',
        items: [],
      },
      {
        label: 'TON',
        href: 'https://ton.gtokentool.com/',
        items: [],
      },
      {
        label: t('Launchpads'),
        href: '/fairlaunch',
        items: [
          {
            label: t('Fairlaunch List'),
            href: '/fairlaunch',
          },
          // {
          //   label: t('Pre-sale list'),
          //   href: '/launchpad',
          // },
          {
            label: t('Create Fairlaunch'),
            href: '/fairlaunch/create',
          },
          // {
          //   label: t('Create Presale'),
          //   href: '/launchpad/create',
          // },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      // {
      //   label: t('Guess'),
      //   href: '/guessList',
      //   items: [
      //     {
      //       label: t('Guess List'),
      //       href: '/guessList',
      //     },
      //     {
      //       label: t('Create Guess'),
      //       href: '/guess',
      //     },
      //   ].map((item) => addMenuItemSupported(item, chainId)),
      // },
      {
        label: t('Create Token'),
        href: '/tokenfactory',
        icon: 'token',
        items: [
          {
            label: t('Create Token'),
            href: '/tokenfactory',
          },
          {
            label: `${t('Create')}NFT`,
            href: '/createnft',
          },
          {
            label: t('Manage Tokens'),
            href: '/managetokens',
          },
          {
            label: t('Token Tool'),
            href: '/tool',
          },
          // {
          //   label: t('Avatar on ave'),
          //   href: '/onave',
          // },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('Tools'),
        href: '/sendertoken',
        // icon: 'batch',
        hideSubNav: false,
        items: [
          {
            label: t('Send tokens'),
            href: '/sendertoken',
          },
          {
            label: t('Send NFT In Batches'),
            href: '/sendernft',
          },
          {
            label: t('批量检查钱包余额'),
            href: '/batchCheckBalance',
          },
          {
            label: t('批量归集'),
            href: 'https://robotv2.gtokentool.com/#/collect',
          },
          {
            label: t('Add Currency Address'),
            href: '/increaseAddr',
          },
          {
            type: DropdownMenuItemType.DIVIDER,
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('Runes'),
        href: '/fwlist',
        // icon: 'wa',
        items: [
          {
            label: t('Rune list'),
            href: '/fwlist',
          },
          {
            label: t('Create runes'),
            href: '/createInscription',
          },
          {
            label: t('Management Runes'),
            href: '/createInscription/list',
          },
          {
            label: t('Batch Inscription'),
            href: '/inscription',
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('Trade'),
        href: '/swap',
        // icon: 'trade',
        showItemsOnMobile: false,
        items: [
          {
            label: t('Swap'),
            href: '/swap',
          },
          {
            label: t('Liquidity'),
            href: '/liquidity',
          },
          {
            label: t('创建流动性并买入(开发中)'),
            href: '/createLiquidityAndBuy',
          },
          {
            label: t('batchSwap'),
            href: '/batchSwap',
          },
          {
            label: t('跟单机器人(开发)'),
            href: '/copyRobot',
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },

      {
        label: t('Create IDO'),
        href: '/idov2',
        // icon: 'createido',
        items: [
          {
            label: t('Create IDO'),
            href: '/idov2',
          },
          {
            label: t('IDO Management'),
            href: '/idov2/list',
          },
          // {
          //   label: t('IDO Total'),
          //   href: '/idototal',
          // },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('Create Mining'),
        href: '/mining',
        icon: 'wa',
        items: [
          {
            label: t('Create Mining'),
            href: '/mining',
          },
          {
            label: t('创建质押NFT'),
            href: '/pledgeNFT',
          },
          {
            label: t('NFT casting'),
            href: '/tokencreatenft',
          },
          {
            label: t('Manage Mining'),
            href: '/tokencreatenft/list',
          },
          {
            label: t('Mining list'),
            href: '/miningList',
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },

      {
        label: t('Market Value'),
        href: '/bot',
        // icon: 'div',
        showItemsOnMobile: false,
        items: [
          {
            label: t('Market Bot'),
            href: '/bot',
          },
          {
            label: t('Bot'),
            href: 'https:/robotv2.gtokentool.com',
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },

      {
        label: t('G Lock'),
        href: '/createlock',
        // icon: 'firing',
        items: [
          {
            label: t('Create Lock'),
            href: '/createlock',
          },
          {
            label: t('Token Lock'),
            href: '/tokenlock',
          },
          {
            label: t('LP Lock'),
            href: '/lplock',
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },

      {
        label: t('Docs'),
        href: '/uploadImage',
        // icon: 'boss',
        hideSubNav: false,
        items: [
          {
            label: t('Bulk Upload Images'),
            href: '/uploadImage',
          },
          {
            label: t('Contract Number Tool'),
            href: '/liang',
          },
          {
            type: DropdownMenuItemType.DIVIDER,
          },
          {
            label: t('Docs'),
            href: 'https://docs.gtokentool.com',
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('...'),
        href: '/others/task',
        hideSubNav: false,
        items: [
          {
            label: t('Post a task'),
            href: '/others/task',
          },
          {
            label: t('Task List'),
            href: '/others/taskList',
          },
          {
            label: t('第三方支付(开发中)'),
            href: '/others/thirdPayment',
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
    ].map((item) => addMenuItemSupported(item, chainId))

export default config

export const nftConfig: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('NFT casting'),
      href: '/tokencreatenft/[id]',
      items: [
        {
          label: t('NFT casting'),
          href: '/tokencreatenft/[id]',
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))
