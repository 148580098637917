import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
import { Token } from "./entities/token";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["ETHEREUM"] = 1] = "ETHEREUM";
    ChainId[ChainId["RINKEBY"] = 4] = "RINKEBY";
    ChainId[ChainId["GOERLI"] = 5] = "GOERLI";
    ChainId[ChainId["BSC"] = 56] = "BSC";
    ChainId[ChainId["BSC_TESTNET"] = 97] = "BSC_TESTNET";
    ChainId[ChainId["AVALANCHE"] = 43114] = "AVALANCHE";
    ChainId[ChainId["POLYGON"] = 137] = "POLYGON";
    ChainId[ChainId["OPTIMISM"] = 10] = "OPTIMISM";
    ChainId[ChainId["DOGECHAIN"] = 2000] = "DOGECHAIN";
    ChainId[ChainId["OKXCHAIN"] = 66] = "OKXCHAIN";
    ChainId[ChainId["MBKCHAIN"] = 12133] = "MBKCHAIN";
    ChainId[ChainId["ETCCHAIN"] = 61] = "ETCCHAIN";
    ChainId[ChainId["ETHW"] = 10001] = "ETHW";
    ChainId[ChainId["ETF"] = 513100] = "ETF";
    ChainId[ChainId["ARB"] = 42161] = "ARB";
    ChainId[ChainId["CORE"] = 1116] = "CORE";
    ChainId[ChainId["BASE"] = 8453] = "BASE";
    ChainId[ChainId["opBNB"] = 204] = "opBNB";
    ChainId[ChainId["HECO"] = 128] = "HECO";
    ChainId[ChainId["PEGO"] = 20201022] = "PEGO";
    ChainId[ChainId["OKB"] = 196] = "OKB";
    ChainId[ChainId["APE"] = 33139] = "APE";
    ChainId[ChainId["SEPOLIA"] = 11155111] = "SEPOLIA";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
export var FACTORY_ADDRESS = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73";
// // TODO: ETH This is test version, do not depends on it
var FACTORY_ADDRESS_ETH = "0xD93801d7D3a368D94A3A32E97A20f7aC1948a5dB";
var _obj;
// const FACTORY_ADDRESS_ETH = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f'
export var FACTORY_ADDRESS_MAP = (_obj = {}, // [ChainId.ETHEREUM]: FACTORY_ADDRESS_ETH,
_define_property(_obj, ChainId.ETHEREUM, "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f"), _define_property(_obj, ChainId.RINKEBY, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.GOERLI, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.BSC, FACTORY_ADDRESS), _define_property(_obj, ChainId.BSC_TESTNET, "0x6725f303b657a9451d8ba641348b6761a6cc7a17"), _define_property(_obj, ChainId.ARB, "0xc35dadb65012ec5796536bd9864ed8773abc74c4"), _define_property(_obj, ChainId.CORE, "0x425E4b9f455849671De93d02A2D6eb132355274E"), _define_property(_obj, ChainId.MBKCHAIN, "0x872CF2b748E594d766500f75005E9Cd7E59508a7"), _define_property(_obj, ChainId.BASE, "0xd16e3763db63761cd5b38214edc062fb43776ca3"), _define_property(_obj, ChainId.opBNB, "0xd50aaE6C73E2486B0Da718D23F35Dcf5aad25911"), _define_property(_obj, ChainId.HECO, "0xb0b670fc1F7724119963018DB0BfA86aDb22d941"), _define_property(_obj, ChainId.PEGO, "0x94274b1Ed6E1BDe55fa631f502Aa18512Aa3007b"), _define_property(_obj, ChainId.OKB, "0xa7afb6163c331ddb0845843889d6f9544328846f"), _define_property(_obj, ChainId.APE, "0x06f2b773a8dD3021e51FC024BeE1e942b796BEe7"), _define_property(_obj, ChainId.SEPOLIA, "0x9a2E752a6b8e80bDa9e12A393F6bd269CD97F6b8"), _define_property(_obj, ChainId.POLYGON, "0x9e5A52f57b3038F1B8EeE45F28b3C1967e22799C"), _obj);
export var INIT_CODE_HASH = "0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5";
var INIT_CODE_HASH_ETH = "0x57224589c67f3f30a6b0d7a1b54cf3153ab84563bc609ef41dfb34f8b2974d2d";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, // [ChainId.ETHEREUM]: INIT_CODE_HASH_ETH,
_define_property(_obj1, ChainId.ETHEREUM, "0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f"), _define_property(_obj1, ChainId.RINKEBY, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.GOERLI, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.BSC, INIT_CODE_HASH), // [ChainId.MBKCHAIN]: INIT_CODE_HASH,
_define_property(_obj1, ChainId.MBKCHAIN, "0xa5934690703a592a07e841ca29d5e5c79b5e22ed4749057bb216dc31100be1c0"), _define_property(_obj1, ChainId.BSC_TESTNET, "0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66"), _define_property(_obj1, ChainId.ARB, "0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303"), _define_property(_obj1, ChainId.CORE, "0x5bc0c987398ee8f9be4436590a75eab19e9feb184d6cea41867f8805b6d0f2bb"), _define_property(_obj1, ChainId.BASE, "0x6c758b28009887c7a79e6472ce2aedfd8af5f34b2a321ee81ed090cebe5256aa"), _define_property(_obj1, ChainId.opBNB, "0x6c758b28009887c7a79e6472ce2aedfd8af5f34b2a321ee81ed090cebe5256aa"), _define_property(_obj1, ChainId.HECO, "0x2ad889f82040abccb2649ea6a874796c1601fb67f91a747a80e08860c73ddf24"), _define_property(_obj1, ChainId.PEGO, "0x6e9d1a9446dcdf45071ca6c9bedfdfa09b568b1a208558039c34e9aa660bb9da"), _define_property(_obj1, ChainId.OKB, "0xb79803c7b3e6448ffb477bfb01feef39c5f9e33a23c5e1c3c444426561221cf5"), _define_property(_obj1, ChainId.APE, "0x09c30db4dd75533e64762cbd9a31f900a39da5ae8f187c28b1cb56a2c222fe06"), _define_property(_obj1, ChainId.SEPOLIA, "0xda9dd58cb2c2ee6d8c17aee1c79bcbd39b94f709ed1a9059234ed5158de8ffb9"), _define_property(_obj1, ChainId.POLYGON, "0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f"), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var _9975 = JSBI.BigInt(9975);
export var _10000 = JSBI.BigInt(10000);
export var MaxUint256 = JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
var _obj3;
export var WETH9 = (_obj3 = {}, _define_property(_obj3, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.RINKEBY, new Token(ChainId.RINKEBY, "0xc778417E063141139Fce010982780140Aa0cD5Ab", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.GOERLI, new Token(ChainId.GOERLI, "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.ARB, new Token(ChainId.ARB, "0x82af49447d8a07e3bd95bd0d56f35241523fbab1", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.CORE, new Token(ChainId.CORE, "0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.BASE, new Token(ChainId.BASE, "0x4200000000000000000000000000000000000006", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.PEGO, new Token(ChainId.PEGO, "0x0cf4071940782b640d0b595cb17bdf3e90869d70", 18, "WPG", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.OKB, new Token(ChainId.OKB, "0xe538905cf8410324e03A5A23C1c177a474D59b2b", 18, "WOKB", "OKB", "https://weth.io")), _define_property(_obj3, ChainId.APE, new Token(ChainId.APE, "0x48b62137EdfA95a428D35C09E44256a739F6B557", 18, "WAPE", "APE", "https://weth.io")), _define_property(_obj3, ChainId.SEPOLIA, new Token(ChainId.SEPOLIA, "0xD16E3763Db63761CD5b38214EdC062FB43776CA3", 18, "WETH", "WETH", "https://weth.io")), _define_property(_obj3, ChainId.POLYGON, new Token(ChainId.POLYGON, "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270", 18, "WPOL", "WPOL", "https://weth.io")), _obj3);
var _obj4;
export var WBNB = (_obj4 = {}, _define_property(_obj4, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0x418D75f65a02b3D53B2418FB8E1fe493759c7605", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _define_property(_obj4, ChainId.BSC, new Token(ChainId.BSC, "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _define_property(_obj4, ChainId.BSC_TESTNET, new Token(ChainId.BSC_TESTNET, "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _define_property(_obj4, ChainId.ARB, WETH9[ChainId.ARB]), _define_property(_obj4, ChainId.CORE, WETH9[ChainId.CORE]), _define_property(_obj4, ChainId.BASE, WETH9[ChainId.BASE]), _define_property(_obj4, ChainId.PEGO, WETH9[ChainId.PEGO]), _define_property(_obj4, ChainId.OKB, WETH9[ChainId.OKB]), _define_property(_obj4, ChainId.APE, WETH9[ChainId.APE]), _define_property(_obj4, ChainId.SEPOLIA, WETH9[ChainId.SEPOLIA]), _define_property(_obj4, ChainId.POLYGON, WETH9[ChainId.POLYGON]), _obj4);
var _obj5;
export var WNATIVE = (_obj5 = {}, _define_property(_obj5, ChainId.ETHEREUM, WETH9[ChainId.ETHEREUM]), _define_property(_obj5, ChainId.RINKEBY, WETH9[ChainId.RINKEBY]), _define_property(_obj5, ChainId.GOERLI, WETH9[ChainId.GOERLI]), _define_property(_obj5, ChainId.BSC, WBNB[ChainId.BSC]), _define_property(_obj5, ChainId.BSC_TESTNET, WBNB[ChainId.BSC_TESTNET]), _define_property(_obj5, ChainId.ARB, WETH9[ChainId.ARB]), _define_property(_obj5, ChainId.CORE, WETH9[ChainId.CORE]), _define_property(_obj5, ChainId.BASE, WETH9[ChainId.BASE]), _define_property(_obj5, ChainId.PEGO, WETH9[ChainId.PEGO]), _define_property(_obj5, ChainId.OKB, WETH9[ChainId.OKB]), _define_property(_obj5, ChainId.APE, WETH9[ChainId.APE]), _define_property(_obj5, ChainId.SEPOLIA, WETH9[ChainId.SEPOLIA]), _define_property(_obj5, ChainId.POLYGON, WETH9[ChainId.POLYGON]), _obj5);
var _obj6;
export var NATIVE = (_obj6 = {}, _define_property(_obj6, ChainId.ETHEREUM, {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj6, ChainId.RINKEBY, {
    name: "Rinkeby Ether",
    symbol: "RIN",
    decimals: 18
}), _define_property(_obj6, ChainId.GOERLI, {
    name: "Goerli Ether",
    symbol: "GOR",
    decimals: 18
}), _define_property(_obj6, ChainId.BSC, {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18
}), _define_property(_obj6, ChainId.BSC_TESTNET, {
    name: "Binance Chain Native Token",
    symbol: "tBNB",
    decimals: 18
}), _define_property(_obj6, ChainId.ARB, {
    name: "eth Token",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj6, ChainId.CORE, {
    name: "core Token",
    symbol: "CORE",
    decimals: 18
}), _define_property(_obj6, ChainId.BASE, {
    name: "eth Token",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj6, ChainId.PEGO, {
    name: "PEGO",
    symbol: "PEGO",
    decimals: 18
}), _define_property(_obj6, ChainId.OKB, {
    name: "OKB",
    symbol: "OKB",
    decimals: 18
}), _define_property(_obj6, ChainId.APE, {
    name: "APE",
    symbol: "APE",
    decimals: 18
}), _define_property(_obj6, ChainId.SEPOLIA, {
    name: "SEPOLIAETH",
    symbol: "SEPETH",
    decimals: 18
}), _define_property(_obj6, ChainId.POLYGON, {
    name: "POLYGON",
    symbol: "POL",
    decimals: 18
}), _obj6);
